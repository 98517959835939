import Image from 'next/image';
import Link from 'next/link';

import notFound from './assets/notFound.svg';

import sad from '@/assets/sad.svg';
import PrimaryButton from '@/components/Module/PrimaryButton';
import useViewport from '@/hooks/useViewport';

export type NotFoundProps = {};

const MobileNotFound = (props: NotFoundProps) => {
  const {} = props;

  return (
    <div
      className={`relative flex min-h-screen flex-col justify-between px-[24px] pb-[40px] pt-[130px]`}
    >
      <div className={`mb-[40px] flex w-full flex-col items-center`}>
        <Image width={140} height={140} src={sad} alt="sad" />
        <p
          className={`mt-[8px] text-center font-roboto text-h2 font-bold text-gray-600`}
        >
          Page not found
        </p>
        <p
          className={`mt-[8px] text-center font-roboto text-b2 font-normal text-gray-200`}
        >
          The page you’re looking for doesn’t exist.
        </p>
      </div>
      <Link href={`/`}>
        <a className={`contents`}>
          <PrimaryButton>Home</PrimaryButton>
        </a>
      </Link>
    </div>
  );
};

const DesktopNotFound = (props: NotFoundProps) => {
  const {} = props;

  return (
    <div
      className={`absolute flex min-h-screen w-full items-center justify-center`}
    >
      <div className={`flex flex-col`}>
        <Image width={498} height={154} src={notFound} alt="notFound" />
        <p
          className={`mt-[8px] text-center font-roboto text-h2 font-bold text-gray-600`}
        >
          Page not found
        </p>
        <p
          className={`mt-[8px] text-center font-roboto text-b2 font-normal text-gray-200`}
        >
          The page you’re looking for doesn’t exist.
        </p>
        <div className={`mt-[72px]`}>
          <Link href={`/`}>
            <a className={`contents`}>
              <PrimaryButton>Home</PrimaryButton>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const NotFound = (props: NotFoundProps) => {
  const {} = props;
  const viewport = useViewport();

  if (viewport == `mobile`) {
    return <MobileNotFound {...props} />;
  }
  if (viewport == `desktop` || viewport == `tablet`) {
    return <DesktopNotFound {...props} />;
  }

  return null;
};

export default NotFound;
